<template>
  <v-card color="light_gray" elevation="0">
    <div class="address-container mb-8">
      <div v-for="(address, index) in customerInfo.addresses" :key="`changeAddress_${index}`" class="mb-4">
        <v-hover v-slot="{ hover }">
          <div class="address-box rounded-lg pa-4 cursor-pointer" :class="hover || address.uuid == selectedAddressId ? 'active' : ''" @click="selectThisAddress(address.uuid)">
            <v-row no-gutters justify="space-between">
              <v-col cols="auto">
                <div class="font-weight-bold">{{ address.display_name }}</div>
              </v-col>
              <v-col cols="auto">
                <v-btn text min-width="0" :ripple="false" width="auto" height="auto" min-height="0" plain class="mr-4 pa-0" @click="editAddress(address)">
                  <span class="unbox_primary--text text-capitalize font-weight-bold text-body-2 cursor-pointer">{{ $t(`label.edit`) }}</span>
                </v-btn>
              </v-col>
            </v-row>
            <div>({{ address.phone_code }}){{ address.phone_no }}</div>
            <div>{{ address.address_1 }} {{ address.address_2 }} {{ address.address_3 }}</div>
            <div>{{ address.city }} {{ address.postcode }} {{ address.state }}</div>
            <div class="mb-2"></div>
            <div class="success_01--text text-caption" v-if="address.is_default_billing">{{ $t(`label.defaultBillingAddress`) }}</div>
            <div class="success_01--text text-caption" v-if="address.is_default_shipping">{{ $t(`label.defaultShippingAddress`) }}</div>
          </div>
        </v-hover>
      </div>
    </div>

    <v-btn block color="black" class="rounded-lg font-weight-bold text-body-1 py-6 white--text" @click="openAddAddressDialog">
      {{ $t(`label.addAddress`) }}
    </v-btn>
  </v-card>
</template>
<script>
export default {
  props: {
    selectedAddressId: {
      type: String
    },
    updateSelectedAddress: {
      type: Function
    }
  },
  computed: {
    customerInfo() {
      return this.$store.state.customer.info
    }
  },
  methods: {
    openAddAddressDialog() {
      this.$root.$master.changeAddressDialogShow = false
      this.$root.$master.deliveryAddressObj.isEdit = false
      this.$root.$master.deliveryAddressDialogShow = true
      this.$root.$master.deliveryAddressObj.isFirstAddress = this.customerInfo.addresses.length <= 0
    },
    editAddress(address) {
      this.$root.$master.changeAddressDialogShow = false
      this.$root.$master.deliveryAddressObj.isEdit = true
      this.$root.$master.deliveryAddressObj.address = address
      this.$root.$master.deliveryAddressDialogShow = true
      this.$root.$master.deliveryAddressObj.isFirstAddress = this.customerInfo.addresses.length == 1
    },
    selectThisAddress(addressUuid) {
      this.updateSelectedAddress(addressUuid)
    }
  }
}
</script>
<style lang="scss">
.address-container {
  max-height: 400px;
  overflow-y: auto;
}
.address-box {
  border: 1px solid #d5dde0;
  &.active {
    border-color: var(--v-unbox_primary-base);
  }
}
</style>
