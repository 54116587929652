<template>
  <v-card color="transparent" tile elevation="0">
    <v-card class="py-sm-8 py-4" color="light_gray" tile elevation="0">
      <v-row justify="center">
        <v-col cols="11" lg="9">
          <span class="text-sm-h5 text-body-1 font-weight-bold">{{ $t(`label.checkout`) }}</span>
        </v-col>
      </v-row>
    </v-card>
    <v-row justify="center" class="my-8" no-gutters>
      <v-col cols="11" lg="9">
        <v-row>
          <v-col md="7" cols="12">
            <v-card class="pa-sm-6 pa-4 py-6 rounded-lg" color="transparent" tile elevation="5">
              <!-- Delivery Method -->
              <div class="mb-6" v-if="!isDeposit">
                <div class="font-weight-bold mb-2 text-sm-body-1 text-body-2">{{ $t(`label.selectDeliveryMethod`) }}</div>
                <v-row>
                  <v-col v-for="deliveryMethod in orderDeliveryMethods" :key="deliveryMethod.name" cols="12" sm="5" md="6" class="delivery-option-button-box">
                    <div
                      @click="!isMakeOldOrderPayment ? (deliveryMethodId = deliveryMethod.name) : ''"
                      class="cursor-pointer delivery-option-button text-capitalize px-4 py-2 rounded-lg"
                      :class="deliveryMethodId == deliveryMethod.name ? 'active' : ''"
                    >
                      <v-icon :class="deliveryMethodId == deliveryMethod.name ? 'stroke--unbox_primary' : 'stroke--light_gray'" v-text="`$${deliveryMethod.name}Icon`" />
                      <div class="text-body-2" :class="deliveryMethodId == deliveryMethod.name ? 'unbox_black--text' : 'text_light_gray--text'">{{ deliveryMethod.text }}</div>
                    </div>
                  </v-col>
                </v-row>

                <div class="red--text text-body-2 mb-2" v-if="isDeliveryMethodError">{{ $t(`label.invalidDeliveryMethod`) }}</div>
              </div>

              <!-- Shipping Address -->
              <div class="mb-6" v-if="deliveryMethodId == deliveryMethod.DELIVERY">
                <div class="font-weight-bold mb-2 text-sm-body-1 text-body-2">{{ $t(`label.yourShippingAddress`) }}</div>

                <div v-if="shippingAddress != null" class="billing-address-box py-4 px-6 rounded-lg cursor-pointer text-sm-body-1 text-body-2" @click="openChangeShippingAddressDialog">
                  <v-row no-gutters justify="space-between" align="center">
                    <v-col cols="10">
                      <div class="font-weight-bold">{{ shippingAddress.display_name }}</div>
                      <div>{{ shippingAddress.phone }}</div>
                      <div>{{ shippingAddress.address }}</div>
                    </v-col>
                    <v-col cols="auto" v-if="!isMakeOldOrderPayment">
                      <v-icon v-text="'$arrowRightIcon'" class="stroke--unbox_green"></v-icon>
                    </v-col>
                  </v-row>
                </div>
                <div v-else class="billing-address-box py-4 px-6 rounded-lg cursor-pointer text-sm-body-1 text-body-2" @click="openAddAddressDialog">
                  <v-row no-gutters justify="space-between" align="center">
                    <v-col cols="10">
                      <div class="font-weight-bold">{{ $t(`label.addAddress`) }}</div>
                    </v-col>
                    <v-col cols="auto">
                      <v-icon v-text="'$plusCircleIcon'" class="stroke--unbox_green"></v-icon>
                    </v-col>
                  </v-row>
                </div>
                <div class="red--text text-body-2 mb-2" v-if="isShippingAddressError">{{ $t(`label.invalidShippingAddress`) }}</div>
              </div>

              <!-- Billing Address -->
              <div class="mb-6">
                <div class="font-weight-bold mb-2 text-sm-body-1 text-body-2">{{ $t(`label.yourBillingAddress`) }}</div>
                <div v-if="billingAddress != null" class="billing-address-box py-4 px-6 rounded-lg cursor-pointer text-sm-body-1 text-body-2" @click="openChangeBillingAddressDialog">
                  <v-row no-gutters justify="space-between" align="center">
                    <v-col cols="10">
                      <div class="font-weight-bold">{{ billingAddress.display_name }}</div>
                      <div>{{ billingAddress.phone }}</div>
                      <div>{{ billingAddress.address }}</div>
                    </v-col>
                    <v-col cols="auto" v-if="!isMakeOldOrderPayment">
                      <v-icon v-text="'$arrowRightIcon'" class="stroke--unbox_green"></v-icon>
                    </v-col>
                  </v-row>
                </div>
                <div v-else class="billing-address-box py-4 px-6 rounded-lg cursor-pointer text-sm-body-1 text-body-2" @click="openAddAddressDialog">
                  <v-row no-gutters justify="space-between" align="center">
                    <v-col cols="10">
                      <div class="font-weight-bold">{{ $t(`label.addAddress`) }}</div>
                    </v-col>
                    <v-col cols="auto">
                      <v-icon v-text="'$plusCircleIcon'" class="stroke--unbox_green"></v-icon>
                    </v-col>
                  </v-row>
                </div>
                <div class="red--text text-body-2 mb-2" v-if="isBillingAddressError">{{ $t(`label.invalidBillingAddress`) }}</div>
              </div>

              <!-- Checkout Items -->
              <div class="mb-6">
                <div class="font-weight-bold mb-2">{{ $t(`label.yourItems`) }}</div>
                <v-data-table
                  :items-per-page="checkoutCart.length"
                  class="cart-table unbox_scrollbar"
                  item-key="productDisplaySku"
                  :items="checkoutCart"
                  hide-default-footer
                  ref="cartTable"
                  :no-data-text="$t(`label.cartEmpty`)"
                >
                  <template v-slot:item="{ item }">
                    <template>
                      <tr>
                        <td class="full-width px-0 d-table">
                          <v-card class="my-2 mx-1 rounded-lg" color="transparent" tile elevation="2">
                            <table class="full-width d-table">
                              <tr>
                                <td class="cart-productColumn image v-align-top">
                                  <img class="cart-productImage ma-2" :src="`${shared.MEDIA_SERVER_URL}/products/variants/${item.productDisplaySku}.png`" alt="" />
                                </td>
                                <td class="cart-productColumn">
                                  <div class="py-4 px-2">
                                    <div>
                                      <div
                                        class="font-weight-black text-body-1 mb-2 red--text"
                                        :class="
                                          cartStock.length <= 0 ||
                                          (cartStock.find((x) => x.display_sku == item.productDisplaySku) != null && cartStock.find((x) => x.display_sku == item.productDisplaySku).quantity > 0)
                                            ? 'd-none'
                                            : ''
                                        "
                                        v-if="!isDeposit"
                                      >
                                        {{ $t(`label.soldOut`) }}
                                      </div>
                                      <div class="font-weight-bold text-sm-body-1 text-body-2 mb-2">{{ item.productName }}</div>
                                      <div class="text-sm-body-1 text-body-2 mb-2">{{ item.variation }}</div>
                                      <div class="font-weight-bold text-sm-body-1 text-body-2 unbox_red--text mb-2">{{ $shared.formatCurrency(item.discountedPrice) }}</div>
                                    </div>
                                    <v-row align="center" class="mt-2">
                                      <v-col sm="auto">
                                        <span class="text-sm-body-1 text-body-2">{{ $t(`label.quantity`) }}</span>
                                      </v-col>
                                      <v-col sm="auto">
                                        <div style="width: 120px">
                                          <v-text-field
                                            class="cart-quantity"
                                            :readonly="isMakeOldOrderPayment || isClearBalanceOrder"
                                            hide-details
                                            outlined
                                            v-model.number="item.quantity"
                                            @keyup="checkAvailableQuantity(item)"
                                            dense
                                            type="number"
                                          >
                                            <template v-slot:prepend>
                                              <v-btn
                                                :disabled="isMakeOldOrderPayment || isClearBalanceOrder"
                                                class="font-weight-bold my-n2 text-h6 px-0"
                                                max-width="15px"
                                                min-width="15px"
                                                height="40px"
                                                text
                                                @click.stop="decreaseCartItemQuantity(item)"
                                              >
                                                -
                                              </v-btn>
                                            </template>
                                            <template v-slot:append-outer>
                                              <v-btn
                                                :disabled="
                                                  isClearBalanceOrder ||
                                                  isMakeOldOrderPayment ||
                                                  (cartStock.find((x) => x.display_sku == item.productDisplaySku) != null &&
                                                    cartStock.find((x) => x.display_sku == item.productDisplaySku).quantity < item.quantity)
                                                "
                                                class="font-weight-bold my-n2 text-h6 px-0"
                                                max-width="15px"
                                                min-width="15px"
                                                height="40px"
                                                text
                                                @click.stop="increaseCartItemQuantity(item)"
                                              >
                                                +
                                              </v-btn>
                                            </template>
                                          </v-text-field>
                                        </div>
                                      </v-col>
                                    </v-row>
                                    <v-row v-if="item.insurance && item.discountedPrice < 8000.01 && item.discountedPrice > 0">
                                      <v-col cols="12">
                                        <div>
                                          <v-row no-gutters align="top" class="mb-2">
                                            <v-col cols="auto"><v-checkbox class="mt-0" hide-details v-model="item.requiredInsurance"></v-checkbox></v-col>
                                            <v-col>
                                              <p class="mb-0">
                                                {{ $t(`label.insurancePlan`, [$shared.formatCurrency(item.insurance.unit_price)]) }}
                                                <span class="link-text cursor-pointer" @click="openProtectionPlanPage">
                                                  {{ $t(`label.tncsApply`) }}
                                                  <v-icon small v-text="'$exclamationMarkIcon'" class="stroke--link_text"></v-icon>
                                                </span>
                                              </p>
                                            </v-col>
                                          </v-row>
                                          <div v-if="item.requiredInsurance">
                                            <div class="font-weight-bold text-sm-body-1 text-body-2 unbox_red--text mb-2">{{ $shared.formatCurrency(item.insurance.unit_price) }}</div>
                                            <div>
                                              <v-row align="center" class="mt-2">
                                                <v-col sm="auto">
                                                  <span class="text-sm-body-1 text-body-2">{{ $t(`label.quantity`) }}</span>
                                                </v-col>
                                                <v-col sm="auto">
                                                  <div style="width: 120px">
                                                    <v-text-field
                                                      class="cart-quantity"
                                                      hide-details
                                                      outlined
                                                      v-model.number="item.insurance.quantity"
                                                      @keyup="checkInsuranceQuantity(item)"
                                                      dense
                                                      type="number"
                                                    >
                                                      <template v-slot:prepend>
                                                        <v-btn
                                                          class="font-weight-bold my-n2 text-h6 px-0"
                                                          max-width="15px"
                                                          min-width="15px"
                                                          height="40px"
                                                          text
                                                          @click.stop="decreaseInsuranceQuantity(item)"
                                                        >
                                                          -
                                                        </v-btn>
                                                      </template>
                                                      <template v-slot:append-outer>
                                                        <v-btn
                                                          :disabled="
                                                            cartStock.find((x) => x.display_sku == item.productDisplaySku) != null &&
                                                            cartStock.find((x) => x.display_sku == item.productDisplaySku).quantity < item.quantity
                                                          "
                                                          class="font-weight-bold my-n2 text-h6 px-0"
                                                          max-width="15px"
                                                          min-width="15px"
                                                          height="40px"
                                                          text
                                                          @click.stop="increaseInsuranceQuantity(item)"
                                                        >
                                                          +
                                                        </v-btn>
                                                      </template>
                                                    </v-text-field>
                                                  </div>
                                                </v-col>
                                              </v-row>
                                            </div>
                                          </div>
                                        </div>
                                      </v-col>
                                    </v-row>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </v-card>
                        </td>
                      </tr>
                    </template>
                  </template>
                </v-data-table>
              </div>

              <!-- Gift Wrapping & Engraving -->
              <div class="gift-wrapping-engraving-section mb-6 pa-1 full-width" v-if="maxFreeWrapping > 0 && !isDeposit">
                <v-divider class="my-4"></v-divider>
                <v-expansion-panels flat v-model="giftWrappingModel" class="my-2 rounded-lg elevation-1" color="transparent" tile border-color="#D5DDE0">
                  <v-expansion-panel class="elevation-2 rounded-lg">
                    <v-expansion-panel-header color="#FFFFFF" class="pa-2 rounded-lg">
                      <div class="text-body-2 font-weight-bold mt-2 px-4">
                        {{ $t(`label.giftWrapping`) }}
                        <br />
                        <div class="text-body-2 font-weight-normal mt-2 mb-4">
                          <span class="unbox_red--text">{{ $t(`label.giftWrappingLimitedOffer`) }}</span>
                          {{ $t(`label.giftWrappingDesc`) }}
                        </div>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content color="#FFFFFF" class="rounded-lg">
                      <v-data-table
                        :items-per-page="giftWrappingDataset.length"
                        class="cart-table unbox_scrollbar px-4"
                        item-key="productDisplaySku"
                        :items="giftWrappingDataset"
                        hide-default-footer
                        ref="cartTable"
                        :no-data-text="$t(`label.cartEmpty`)"
                      >
                        <template v-slot:item="{ item }">
                          <template>
                            <tr>
                              <td class="full-width px-0 d-table">
                                <v-card class="my-2 mx-1 rounded-lg" color="transparent" tile elevation="2">
                                  <table class="full-width d-table">
                                    <tr>
                                      <td class="cart-productColumn image v-align-top">
                                        <img class="cart-productImage ma-2" :src="`${shared.MEDIA_SERVER_URL}/${item.imagePath}`" alt="" />
                                      </td>
                                      <td class="cart-productColumn">
                                        <div class="py-4 px-2">
                                          <div>
                                            <div class="font-weight-bold text-sm-body-1 text-body-2 mb-2">{{ item.name }}</div>
                                            <div class="font-weight-bold unbox_red--text mb-2 text-uppercase">
                                              <span class="font-weight-bold text-sm-body-1 text-body-2">RM 0.00&nbsp;</span>
                                              <span class="font-weight-light text-decoration-line-through unbox-secondary">{{ $shared.formatCurrency(item.sellingPrice) }}</span>
                                            </div>
                                          </div>
                                          <v-row align="center" class="mt-2">
                                            <v-col sm="auto">
                                              <span class="text-sm-body-1 text-body-2">{{ $t(`label.quantity`) }}</span>
                                            </v-col>
                                            <v-col sm="auto">
                                              <div style="width: 120px">
                                                <v-text-field
                                                  class="cart-quantity"
                                                  :readonly="isMakeOldOrderPayment || isClearBalanceOrder"
                                                  hide-details
                                                  outlined
                                                  v-model.number="item.quantity"
                                                  @keyup="checkAvailableGiftWrappingQuantity('gift', item)"
                                                  dense
                                                  type="number"
                                                >
                                                  <template v-slot:prepend>
                                                    <v-btn
                                                      :disabled="isMakeOldOrderPayment || isClearBalanceOrder"
                                                      class="font-weight-bold my-n2 text-h6 px-0"
                                                      max-width="15px"
                                                      min-width="15px"
                                                      height="40px"
                                                      text
                                                      @click.stop="decreaseGiftWrappingQuantity('gift', item)"
                                                    >
                                                      -
                                                    </v-btn>
                                                  </template>
                                                  <template v-slot:append-outer>
                                                    <v-btn
                                                      :disabled="
                                                        isClearBalanceOrder ||
                                                        isMakeOldOrderPayment ||
                                                        (cartStock.find((x) => x.display_sku == item.productDisplaySku) != null &&
                                                          cartStock.find((x) => x.display_sku == item.productDisplaySku).quantity < item.quantity)
                                                      "
                                                      class="font-weight-bold my-n2 text-h6 px-0"
                                                      max-width="15px"
                                                      min-width="15px"
                                                      height="40px"
                                                      text
                                                      @click.stop="increaseGiftWrappingQuantity('gift', item)"
                                                    >
                                                      +
                                                    </v-btn>
                                                  </template>
                                                </v-text-field>
                                              </div>
                                            </v-col>
                                          </v-row>
                                        </div>
                                      </td>
                                    </tr>
                                  </table>
                                </v-card>
                              </td>
                            </tr>
                            <div v-if="giftWrappingConfirmQuantity > 0">
                              <v-expansion-panels flat class="my-2 mt-4" color="transparent" border-color="transparent">
                                <v-expansion-panel v-for="(x, index) in item.quantity" :key="'giftWrapping-' + index" multiple>
                                  <v-expansion-panel-header color="#FFFFFF" class="pa-2 rounded-lg">
                                    <div class="text-sm-body-1 text-body-2 font-weight-bold mt-2 px-4">{{ item.fieldsName }} - {{ index + 1 }} ({{ item.name }})</div>
                                  </v-expansion-panel-header>
                                  <v-expansion-panel-content color="#FFFFFF" class="rounded-lg">
                                    <textarea
                                      v-model="item.giftCardMessage[index]"
                                      class="gift-wrapping-engraving-input text-body-1 pa-4"
                                      maxlength="50"
                                      :placeholder="$t(`label.giftCardMessagePlaceholder`)"
                                    />
                                  </v-expansion-panel-content>
                                  <v-divider class="mt-4"></v-divider>
                                </v-expansion-panel>
                              </v-expansion-panels>
                            </div>
                          </template>
                        </template>
                      </v-data-table>
                      <div class="px-4">
                        <div class="text-body-2 font-weight-bold my-2">{{ $t(`label.giftCardInstruction`) }}</div>
                        <textarea v-model="giftInstruction" class="gift-wrapping-engraving-input text-body-1 pa-4" :placeholder="$t(`label.giftCardInstructionPlaceholder`)" />
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

                <v-expansion-panels flat v-model="engravingModel" class="my-2 mt-4 rounded-lg elevation-1" color="transparent" tile border-color="#D5DDE0">
                  <v-expansion-panel class="elevation-2 rounded-lg">
                    <v-expansion-panel-header color="#FFFFFF" class="pa-2 rounded-lg">
                      <div class="text-body-2 font-weight-bold mt-2 px-4">
                        {{ $t(`label.engraving`) }}
                        <br />
                        <div class="text-body-2 font-weight-normal mt-2 mb-4">
                          <span class="unbox_red--text">{{ $t(`label.giftWrappingLimitedOffer`) }}</span>
                          {{ $t(`label.engravingDesc`) }}
                        </div>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content color="#FFFFFF" class="rounded-lg">
                      <v-data-table
                        :items-per-page="engravingDataset.length"
                        class="cart-table unbox_scrollbar px-4"
                        item-key="productDisplaySku"
                        :items="engravingDataset"
                        hide-default-footer
                        ref="cartTable"
                        :no-data-text="$t(`label.cartEmpty`)"
                      >
                        <template v-slot:item="{ item }">
                          <template>
                            <tr>
                              <td class="full-width px-0 d-table">
                                <v-card class="my-2 mx-1 rounded-lg" color="transparent" tile elevation="2">
                                  <table class="full-width d-table">
                                    <tr>
                                      <td class="cart-productColumn image v-align-top">
                                        <img class="cart-productImage ma-2" :src="`${shared.MEDIA_SERVER_URL}/${item.imagePath}`" alt="" />
                                      </td>
                                      <td class="cart-productColumn">
                                        <div class="py-4 px-2">
                                          <div>
                                            <div class="font-weight-bold text-sm-body-1 text-body-2 mb-2">{{ item.name }}</div>
                                            <div class="font-weight-bold unbox_red--text mb-2 text-uppercase">
                                              <span class="font-weight-bold text-sm-body-1 text-body-2">RM0.00&nbsp;</span>
                                              <span class="font-weight-light text-decoration-line-through unbox-secondary">{{ $shared.formatCurrency(item.sellingPrice) }}</span>
                                            </div>
                                          </div>
                                          <v-row align="center" class="mt-2">
                                            <v-col sm="auto">
                                              <span class="text-sm-body-1 text-body-2">{{ $t(`label.quantity`) }}</span>
                                            </v-col>
                                            <v-col sm="auto">
                                              <div style="width: 120px">
                                                <v-text-field
                                                  class="cart-quantity"
                                                  :readonly="isMakeOldOrderPayment || isClearBalanceOrder"
                                                  hide-details
                                                  outlined
                                                  v-model.number="item.quantity"
                                                  @keyup="checkAvailableGiftWrappingQuantity('engText', item)"
                                                  dense
                                                  type="number"
                                                >
                                                  <template v-slot:prepend>
                                                    <v-btn
                                                      :disabled="isMakeOldOrderPayment || isClearBalanceOrder"
                                                      class="font-weight-bold my-n2 text-h6 px-0"
                                                      max-width="15px"
                                                      min-width="15px"
                                                      height="40px"
                                                      text
                                                      @click.stop="decreaseGiftWrappingQuantity('engText', item)"
                                                    >
                                                      -
                                                    </v-btn>
                                                  </template>
                                                  <template v-slot:append-outer>
                                                    <v-btn
                                                      :disabled="
                                                        isClearBalanceOrder ||
                                                        isMakeOldOrderPayment ||
                                                        (cartStock.find((x) => x.display_sku == item.productDisplaySku) != null &&
                                                          cartStock.find((x) => x.display_sku == item.productDisplaySku).quantity < item.quantity)
                                                      "
                                                      class="font-weight-bold my-n2 text-h6 px-0"
                                                      max-width="15px"
                                                      min-width="15px"
                                                      height="40px"
                                                      text
                                                      @click.stop="increaseGiftWrappingQuantity('engText', item)"
                                                    >
                                                      +
                                                    </v-btn>
                                                  </template>
                                                </v-text-field>
                                              </div>
                                            </v-col>
                                          </v-row>
                                        </div>
                                      </td>
                                    </tr>
                                  </table>
                                </v-card>
                              </td>
                            </tr>
                            <div v-if="engravingConfirmQuantity > 0">
                              <v-form>
                                <v-expansion-panels flat class="my-2 mt-4" color="transparent" border-color="transparent">
                                  <v-expansion-panel v-for="(x, index) in item.quantity" :key="'giftWrapping-' + index" multiple>
                                    <v-expansion-panel-header color="#FFFFFF" class="pa-2 rounded-lg">
                                      <div class="text-sm-body-1 text-body-2 font-weight-bold mt-2">{{ item.fieldsName }} - {{ index + 1 }} ({{ item.name }})</div>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content color="#FFFFFF" class="rounded-lg">
                                      <app-form-field
                                        v-model="item.giftCardMessage[index]"
                                        type="textarea"
                                        class="gift-wrapping-engraving-input text-body-1 pa-4"
                                        :rows="2"
                                        :placeholder="$t(`label.engravingTextPlaceholder`)"
                                        :rules="$validation.serviceRules(item.fieldsIsRequired, item.fieldsRegex)"
                                      />
                                    </v-expansion-panel-content>
                                    <v-divider class="mt-4"></v-divider>
                                  </v-expansion-panel>
                                </v-expansion-panels>
                              </v-form>
                            </div>
                          </template>
                        </template>
                      </v-data-table>
                      <div class="red--text text-body-2 ml-4 mb-2" v-if="engravingError">{{ $t(`label.incompleteEngraving`) }}</div>

                      <div class="px-4">
                        <div class="text-body-2 font-weight-bold my-2">{{ $t(`label.giftCardInstruction`) }}</div>
                        <textarea v-model="engravingInstruction" class="gift-wrapping-engraving-input text-body-1 pa-4" :placeholder="$t(`label.engravingInstructionPlaceholder`)" />
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>

              <!-- Payment Method -->
              <div class="mb-6">
                <div class="font-weight-bold mb-2 text-sm-body-1 text-body-2">{{ $t(`label.selectPaymentMethod`) }}</div>
                <v-row>
                  <v-col cols="12" sm="5" md="6" v-for="gateway in paymentGateways" :key="gateway.value" class="delivery-option-button-box">
                    <div
                      @click="paymentGatewayId = gateway.value"
                      class="cursor-pointer delivery-option-button text-capitalize px-4 py-2 rounded-lg"
                      :class="paymentGatewayId == gateway.value ? 'active' : ''"
                    >
                      <img class="checkout-payment-icon" :src="`${shared.MEDIA_SERVER_URL}/images/payment_gateways/icons/${gateway.name.toLowerCase()}.png`" alt="" />
                      <div class="text-body-2" :class="paymentGatewayId == gateway.value ? 'unbox_black--text' : 'text_light_gray--text'">{{ gateway.text }}</div>
                    </div>
                  </v-col>
                </v-row>

                <div class="red--text text-body-2 mb-2" v-if="isPaymentMethodError">{{ $t(`label.invalidPaymentMethod`) }}</div>
              </div>
              <!-- Pick Up Location Address -->
              <div class="mb-6" v-if="deliveryMethodId == deliveryMethod.SELF_PICKUP">
                <div class="font-weight-bold mb-2 text-sm-body-1 text-body-2">{{ $t(`label.pickUpLocation`) }}</div>
                <v-select
                  class="mb-2"
                  v-model="outletId"
                  hide-details
                  dense
                  :placeholder="$t(`label.pleaseSelect`)"
                  :items="outlets"
                  @change="updateOutletInfo"
                  :disabled="isMakeOldOrderPayment"
                ></v-select>
                <div v-if="outletInfo != null" class="pickup-location-box text-sm-body-1 text-body-2">
                  <div>{{ outletInfo.addressOne }}</div>
                  <div>{{ outletInfo.addressTwo }} {{ outletInfo.addressThree }}</div>
                  <div>{{ outletInfo.postcode }} {{ outletInfo.city }} {{ outletInfo.state }}</div>
                </div>
                <div class="red--text text-body-2 mb-2" v-if="isSelectOutletError">{{ $t(`label.invalidOutlet`) }}</div>
              </div>
            </v-card>
          </v-col>
          <v-col md="5" cols="12">
            <v-card class="pa-6 rounded-lg" color="transparent" tile elevation="5">
              <v-row justify="space-between" class="mb-3" no-gutters>
                <v-col cols="12" class="ma-0 pa-0"></v-col>
                <v-col cols="auto">
                  <div class="unbox_black--text text-body-1">{{ $t(`label.shippingSubtotal`) }}</div>
                </v-col>
                <v-col cols="auto">
                  <div class="unbox_black--text text-body-1">
                    {{ $shared.formatCurrency(0) }}
                  </div>
                </v-col>
                <v-col cols="12" class="ma-0 pa-0 mb-2"></v-col>
                <v-col cols="auto">
                  <div class="unbox_black--text text-body-1">{{ $t(`label.tax`) }}</div>
                </v-col>
                <v-col cols="auto">
                  <div class="unbox_black--text text-body-1">
                    {{ $shared.formatCurrency(0) }}
                  </div>
                </v-col>
                <v-col cols="12" class="ma-0 pa-0 mb-2"></v-col>
                <v-col cols="auto">
                  <div class="unbox_black--text text-body-1">{{ $t(`label.subtotal`) }}</div>
                </v-col>
                <v-col cols="auto">
                  <div class="unbox_black--text text-body-1">
                    {{ $shared.formatCurrency(cartSubtotalAmount) }}
                  </div>
                </v-col>
                <v-col cols="12" class="ma-0 pa-0 mb-2"></v-col>
                <v-col cols="auto" v-if="isDeposit || isClearBalanceOrder || depositAmount != 0">
                  <div class="unbox_black--text text-body-1">{{ $t(`label.depositAmount`) }}</div>
                </v-col>
                <v-col cols="auto" v-if="isDeposit || isClearBalanceOrder || depositAmount != 0">
                  <div class="unbox_black--text text-body-1">-{{ $shared.formatCurrency(isDeposit ? depositAmount * checkoutCart[0].quantity : depositAmount) }}</div>
                </v-col>
                <v-col cols="12" class="ma-0 pa-0 mb-2"></v-col>
                <v-col cols="auto" v-if="!isDeposit">
                  <div class="unbox_black--text text-body-1">{{ $t(`label.promoCode`) }}</div>
                </v-col>
                <v-col cols="7" v-if="!isDeposit">
                  <div class="unbox_black--text text-body-1 text-right">
                    <v-btn
                      v-if="!isEnterPromoCodeMode && !isValidPromoCode"
                      depressed
                      text
                      class="cursor-pointer text-decoration-underline px-0 link-button text-capitalize blue--text"
                      @click="isEnterPromoCodeMode = true"
                    >
                      Enter Promo Code
                    </v-btn>
                    <div v-else-if="!isValidPromoCode" class="text-right">
                      <v-text-field ref="promoCodeField" class="mb-2" outlined v-model="promoCode" dense hide-details @keydown.enter="applyPromoCode"></v-text-field>
                      <div class="red--text text-caption mb-2" v-if="invalidPromoCodeError">{{ invalidPromoCodeError }}</div>
                      <v-btn type="button" depressed text class="cursor-pointer text-decoration-underline px-0 link-button text-capitalize blue--text" @click="applyPromoCode">Apply</v-btn>
                    </div>
                    <div v-else class="text-right">
                      <div class="font-weight-bold text-body-1">{{ promoCode.toUpperCase() }}</div>
                      <v-btn type="button" depressed text class="cursor-pointer text-decoration-underline px-0 link-button text-capitalize blue--text" @click="changePromoCode">Change</v-btn>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" class="ma-0 pa-0 mb-2"></v-col>
                <v-col cols="auto" v-if="!isDeposit">
                  <div class="unbox_black--text text-body-1">{{ $t(`label.promoDiscount`) }}</div>
                </v-col>
                <v-col cols="auto" v-if="!isDeposit">
                  <div class="unbox_black--text text-body-1">
                    {{ promoDiscount > 0 ? `-${$shared.formatCurrency(promoDiscount)}` : $shared.formatCurrency(promoDiscount) }}
                  </div>
                </v-col>
                <v-col cols="12" class="ma-0 pa-0 mb-2"></v-col>
                <v-col cols="auto">
                  <div class="font-weight-bold unbox_black--text text-body-1">{{ $t(`label.inTotal`) }}</div>
                </v-col>
                <v-col cols="auto">
                  <div class="font-weight-bold unbox_red--text text-body-1">
                    {{ $shared.formatCurrency(cartTotalAmount) }}
                  </div>
                </v-col>
              </v-row>

              <div class="red--text text-body-2 my-4" v-if="noFullEnterCheckoutForm">{{ $t(`label.pleaseSelectCheckout`) }}</div>
              <v-btn block color="unbox_red" class="rounded-lg font-weight-bold text-body-1 py-6 white--text" @click="placeOrder" v-if="!isMakeOldOrderPayment">
                {{ isDeposit ?  $t(`label.payDeposit`) +  &nbsp;` [` + $shared.formatCurrency(depositAmount * checkoutCart[0].quantity) + `]` : $t(`label.placeOrder`) }}
              </v-btn>
              <v-btn block color="unbox_red" class="rounded-lg font-weight-bold text-body-1 py-6 white--text" @click="confirmQuantityBeforePayment" v-if="isMakeOldOrderPayment">
                {{ $t(`label.makePayment`) }}
              </v-btn>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- CHANGE ADDRESS DIALOG -->
    <app-dialog :dialogShow="changeAddressDialogShow" :title="$t(`action.changeAddress`)" :closeAction="() => (changeAddressDialogShow = !changeAddressDialogShow)">
      <template v-slot:body>
        <div class="changeAddressFrom-tab">
          <app-change-address :selectedAddressId="selectedAddressId" :updateSelectedAddress="updateSelectedAddress"></app-change-address>
        </div>
      </template>
    </app-dialog>
  </v-card>
</template>

<script>
import { STOCK_OUTLET_STOCK_QUANTITY_BY_DISPLAY_SKU_FOR_CART, STOCK_RESET_OUTLET_STOCK_QUANTITY_BY_DISPLAY_SKU_FOR_CART_STORE } from '@/store/stock.module'
import { ROUTE_NAME } from '@/constants/route.constants'
import AppChangeAddress from '@/components/shop/changeAddress'
import { ORDER_GET_ORDER_INFO, ORDER_PRE_ORDER, ORDER_NORMAL_ORDER } from '@/store/order.module'
import { CUSTOMER_GET_INFORMATION } from '@/store/customer.module'
import { LOOKUPS_ORDER_DELIVERY_METHODS, LOOKUPS_PAYMENT_TYPES, LOOKUPS_OUTLET_OUTLETS, LOOKUPS_SERVICE_GROUPS } from '@/store/lookup.module'
import { SERVICE_BY_GROUP_ID } from '@/store/service.module'
import { PAYMENT_GATEWAY, PAYMENT_MAKE_PAYMENT } from '@/store/payment.module'
import { sharedHelper, localeHelper } from '@/utils'
import { SESSION, DELIVERY_METHOD, SHARED, PRODUCT_TYPE } from '@/constants'
import { SHARED_CLOSE_DIALOG, SHARED_SHOW_DIALOG } from '@/store/shared.module'
import { LOAN_CHECK_PROMO_CODE } from '@/store/loan.module'
export default {
  name: 'cart',
  components: {
    AppChangeAddress
  },
  data: () => ({
    isClearBalanceOrder: false,
    clearBalanceOrderCart: {},
    isMakeOldOrderPayment: false,
    currentUpdateAddress: '',
    selectedAddressId: '',
    shared: SHARED,
    isDeliveryMethodError: false,
    isPaymentMethodError: false,
    isSelectOutletError: false,
    deliveryMethod: DELIVERY_METHOD,
    promoCode: '',
    checkoutCart: [],
    deliveryMethodId: '',
    isEnterPromoCodeMode: false,
    noFullEnterCheckoutForm: false,
    invalidPromoCodeError: '',
    promoDiscount: 0,
    isValidPromoCode: false,
    billingAddress: null,
    shippingAddress: null,
    paymentGatewayId: '',
    outletId: '',
    outletInfo: null,
    changeAddressDialogShow: false,
    isShippingAddressError: false,
    isBillingAddressError: false,
    engravingError: false,
    maxFreeWrapping: 0,
    giftWrappingModel: 1,
    engravingModel: 1,
    giftWrappingConfirmQuantity: 0,
    engravingConfirmQuantity: 0,
    giftWrappingDataset: [],
    engravingDataset: [],
    focusGiftNo: 0,
    otherGiftNo: 0,
    giftInstruction: '',
    engravingInstruction: '',
    depositAmount: 0,
    isDeposit: false
  }),
  computed: {
    cartStock() {
      return this.$store.state.stock.cartStock
    },
    orderDeliveryMethods() {
      return this.$store.state.lookup.orderDeliveryMethods
    },
    customerInfo() {
      return this.$store.state.customer.info
    },
    editAddressResponseComplete() {
      return this.$store.state.customer.editAddressResponse.complete
    },
    addAddressResponseComplete() {
      return this.$store.state.customer.addAddressResponse.complete
    },
    paymentTypes() {
      return this.$store.state.lookup.paymentTypes
    },
    paymentGateways() {
      return this.$store.state.payment.paymentGateways
    },
    outlets() {
      return this.$store.state.lookup.outlets
    },
    normalOrderResponseComplete() {
      return this.$store.state.order.normalOrderResponse.complete
    },
    makePaymentResponseComplete() {
      return this.$store.state.payment.makePaymentResponse.complete
    },
    order() {
      return this.$store.state.order.order
    },
    productBrands() {
      return this.$store.state.lookup.productBrands
    },
    cartSubtotalAmount() {
      let totalCartAmount = 0

      this.checkoutCart.forEach((product) => {
        totalCartAmount += product.discountedPrice * product.quantity
        if (product.requiredInsurance) {
          totalCartAmount += product.insurance.unit_price * product.insurance.quantity
        }
      })

      return totalCartAmount
    },
    cartTotalAmount() {
      let newDeposit = this.isDeposit ? this.depositAmount * this.checkoutCart[0].quantity : this.depositAmount
      return this.cartSubtotalAmount - this.promoDiscount - newDeposit
    },
    promoCodeData() {
      return this.$store.state.loan.promoCodeData
    },
    preOrderResponseComplete() {
      return this.$store.state.order.preOrderResponse.complete
    },
    updateOrderStatusResponse() {
      return this.$store.state.order.updateOrderStatusResponse
    }
  },
  watch: {
    async editAddressResponseComplete() {
      let response = this.$store.state.customer.editAddressResponse

      if (response.complete) {
        if (!response.success) {
          this.$root.$master.responseCompleteDialog(response)
        } else {
          this.$root.$master.deliveryAddressDialogShow = false
          await this.getCustomerInformation()
        }
      }
    },
    async addAddressResponseComplete() {
      let response = this.$store.state.customer.addAddressResponse

      if (response.complete) {
        if (!response.success) {
          this.$root.$master.responseCompleteDialog(response)
        } else {
          this.$root.$master.deliveryAddressDialogShow = false
          await this.getCustomerInformation()
        }
      }
    },
    normalOrderResponseComplete() {
      let response = this.$store.state.order.normalOrderResponse

      if (response.complete) {
        if (!response.success) {
          if (response.code == '16.1.016') {
            this.$root.$master.responseCompleteDialog(response)

            this.checkoutCart.forEach((x) => {
              let data = {
                displaySku: x.display_sku
              }
              this.$store.dispatch(STOCK_OUTLET_STOCK_QUANTITY_BY_DISPLAY_SKU_FOR_CART, { data })
            })
          } else {
            this.$root.$master.responseCompleteDialog(response)
          }
        } else {
          let d = this.$store.state.order.order
          let data = {
            paymentMethodId: this.paymentGatewayId,
            paymentAmount: d.purchase_price - this.depositAmount,
            currency: d.currency,
            orderReferenceId: d.reference_number,
            customerName: d.email_customer_name,
            customerEmail: d.email_recipient,
            customerPhoneNumber: d.email_phone_number
          }
          localStorage.removeItem(SESSION.CHECKOUT_CART)
          this.updateShoppingCart()
          this.$store.dispatch(PAYMENT_MAKE_PAYMENT, { data })
        }
      }
    },
    async makePaymentResponseComplete() {
      let response = this.$store.state.payment.makePaymentResponse
      if (response.complete) {
        if (!response.success) {
          this.$root.$master.responseCompleteDialog(response)
        } else {
          let d = this.$store.state.payment.paymentTransaction
          d.orderUuid = this.$store.state.order.order.uuid
          d.paymentMethod = this.paymentGateways.find((x) => x.value == this.paymentGatewayId).name
          d.paymentType = this.paymentGateways.find((x) => x.value == this.paymentGatewayId).type[0].payment_type

          await localStorage.setItem(SESSION.PAYMENT_TRANSACTION_INFO, JSON.stringify(d))
          window.location.href = d.redirect_url

          if (this.isDeposit) {
            let d = this.$store.state.order.order
            let data = {
              isSendEmail: true,
              isOrderSealed: true,
              orderUuid: d.uuid,
              action: 'preOrder'
            }
            await this.$store.dispatch(ORDER_UPDATE_STATUS, { data })
          }
        }
      }
    },
    isEnterPromoCodeMode(isEntering) {
      if (isEntering) {
        this.$nextTick(() => {
          this.$refs.promoCodeField.onFocus()
        })
      }
    },
    async preOrderResponseComplete() {
      let response = this.$store.state.order.preOrderResponse
      if (response.complete) {
        if (!response.success) {
          this.$root.$master.responseCompleteDialog(response)
        } else {
          let d = this.$store.state.order.order
          let data = {
            paymentMethodId: this.paymentGatewayId,
            paymentAmount: d.deposit_amount,
            currency: d.currency,
            orderReferenceId: d.reference_number,
            customerName: d.email_customer_name,
            customerEmail: d.email_recipient,
            customerPhoneNumber: d.email_phone_number
          }
          this.$store.dispatch(PAYMENT_MAKE_PAYMENT, { data })
        }
      }
    },
    updateOrderStatusResponse() {
      let response = this.$store.state.order.updateOrderStatusResponse

      if (response.complete) {
        if (!response.success) {
          this.$root.$master.responseCompleteDialog(response)
        } else {
          this.$root.$master.responseCompleteDialog(response, localeHelper.getMessage(`message.preOrderComplete`))
          this.$router.push({
            name: ROUTE_NAME.ORDER_SUMMARY_PAGE,
            params: {
              orderType: 'preorder'
            }
          })
        }
      }
    }
  },
  async created() {
    await this.checkIsNewOrder()
    await this.getServiceGroupLookup()
    await this.getOutlets()
    await this.getDeliveryMethod()
    await this.getCustomerInformation()
    await this.getPaymentTypes()
    await this.getPaymentGateway()
  },
  mounted() {
    this.depositAmount = this.$route.params.depositAmount == undefined ? 0 : this.$route.params.depositAmount
    this.isDeposit = this.$route.params.isDeposit == undefined || this.$route.params.isDeposit != true ? false : true
  },
  methods: {
    openProtectionPlanPage() {
      let route = this.$router.resolve({
        name: ROUTE_NAME.PROTECTION_PLAN
      })
      window.open(route.href, '_blank')
    },
    checkAvailableQuantity(item) {
      let itemStock = this.cartStock.find((x) => x.display_sku == item.productDisplaySku)
      if (item.quantity > itemStock.quantity) {
        item.quantity = itemStock.quantity
        this.$root.$master.showSnackbarMessage(localeHelper.getMessage(`label.remainingItemQuantityMessage`, [itemStock.quantity]))
      } else if (item.quantity <= 0) {
        item.quantity = 1
      }
      this.resetGiftWrappingEngravingAmount()
      this.freeWrappingEngraving()
    },
    increaseCartItemQuantity(item) {
      let itemStock = this.cartStock.find((x) => x.display_sku == item.productDisplaySku)

      if (itemStock != undefined && item.quantity < itemStock.quantity) {
        item.quantity++
        this.maxFreeWrapping++
      }
    },
    decreaseCartItemQuantity(item) {
      if (item.quantity > 1) {
        item.quantity--
        this.maxFreeWrapping--
        this.resetGiftWrappingEngravingAmount()
      }

      if (item.insurance.quantity > item.quantity) {
        item.insurance.quantity = item.quantity
      }
    },
    increaseInsuranceQuantity(item) {
      if (item.insurance.quantity < item.quantity) {
        item.insurance.quantity++
      }
    },
    decreaseInsuranceQuantity(item) {
      if (item.insurance.quantity > 1) {
        item.insurance.quantity--
      }
    },
    checkInsuranceQuantity(item) {
      if (item.insurance.quantity > item.quantity) {
        item.insurance.quantity = item.quantity
        this.$root.$master.showSnackbarMessage(localeHelper.getMessage(`label.insuranceOverProductLimit`, [item.quantity]))
      } else if (item.insurance.quantity < 0) {
        item.insurance.quantity = item.quantity
      } else if (item.quantity <= 0) {
        item.insurance.quantity = 1
      }
    },
    checkAvailableGiftWrappingQuantity(serviceType, item) {
      if (serviceType == 'gift') {
        this.recalculateTotalGiftNo(item.quantity)
        if (this.giftWrappingConfirmQuantity > this.maxFreeWrapping || item.quantity > this.maxFreeWrapping) {
          item.quantity = this.maxFreeWrapping - this.otherGiftNo
          this.giftWrappingConfirmQuantity = this.maxFreeWrapping
          this.$root.$master.showSnackbarMessage(localeHelper.getMessage(`label.remainingWrappingQuantityMessage`))
        } else if (item.quantity <= 0) {
          item.quantity = 0
        } else {
          item.quantity = item.quantity
        }
      }
      if (serviceType == 'engText') {
        if (item.quantity > this.maxFreeWrapping) {
          item.quantity = this.maxFreeWrapping
          this.$root.$master.showSnackbarMessage(localeHelper.getMessage(`label.remainingEngravingQuantityMessage`))
        } else if (item.quantity <= 0) {
          item.quantity = 0
        }
        this.engravingConfirmQuantity = item.quantity
      }
    },
    increaseGiftWrappingQuantity(serviceType, item) {
      if (serviceType == 'gift' && item.quantity < this.maxFreeWrapping && this.giftWrappingConfirmQuantity < this.maxFreeWrapping) {
        item.quantity++
        this.giftWrappingConfirmQuantity++
      } else if (serviceType == 'engText' && item.quantity < this.maxFreeWrapping) {
        item.quantity++
        this.engravingConfirmQuantity = item.quantity
      }
    },
    decreaseGiftWrappingQuantity(serviceType, item) {
      if (serviceType == 'gift' && item.quantity > 0) {
        item.quantity--
        this.giftWrappingConfirmQuantity--
        item.giftCardMessage.length--
      } else if (serviceType == 'engText' && item.quantity > 0) {
        item.quantity--
        this.engravingConfirmQuantity = item.quantity
        item.giftCardMessage.length--
      }
    },
    freeWrappingEngraving() {
      this.maxFreeWrapping = 0
      this.checkoutCart.forEach((x) => {
        this.maxFreeWrapping = this.maxFreeWrapping + x.quantity
      })
    },
    resetGiftWrappingEngravingAmount() {
      if (this.maxFreeWrapping < this.giftWrappingConfirmQuantity) {
        this.giftWrappingDataset.forEach((x) => {
          x.quantity = 0
          x.giftCardMessage.length = 0
          this.giftWrappingConfirmQuantity = 0
        })
      }

      if (this.maxFreeWrapping < this.engravingConfirmQuantity) {
        this.engravingDataset.forEach((x) => {
          x.quantity = 0
          x.giftCardMessage.length = 0
          this.engravingConfirmQuantity = 0
        })
      }
    },
    async getServiceGroupLookup() {
      await this.$store.dispatch(LOOKUPS_SERVICE_GROUPS)
      await this.getServiceByGroupId()
    },
    async getServiceByGroupId() {
      let giftId = this.$store.state.lookup.serviceGroups.find((x) => x.name == 'Gift Wrapping').value
      let engravingId = this.$store.state.lookup.serviceGroups.find((x) => x.name == 'Engraving').value
      await this.$store.dispatch(SERVICE_BY_GROUP_ID, { data: { id: giftId } })
      this.giftWrappingDataset = this.$store.state.service.serviceByGroupid
      await this.$store.dispatch(SERVICE_BY_GROUP_ID, { data: { id: engravingId } })
      this.engravingDataset = this.$store.state.service.serviceByGroupid
      this.recalculateTotalGiftNo()
    },
    recalculateTotalGiftNo(quantity) {
      quantity = quantity == undefined ? -this.maxFreeWrapping : quantity
      this.giftWrappingConfirmQuantity = 0
      this.giftWrappingDataset.forEach((x) => {
        this.giftWrappingConfirmQuantity = this.giftWrappingConfirmQuantity + x.quantity
      })
      this.otherGiftNo = quantity < 0 ? 0 : this.giftWrappingConfirmQuantity - quantity
      this.focusGiftNo = quantity < 0 ? 0 : quantity
    },
    async checkIsNewOrder() {
      let orderId = this.$route.params.orderId
      let action = this.$route.params.action
      this.isMakeOldOrderPayment = false
      this.isClearBalanceOrder = false
      if (orderId != undefined) {
        let data = {
          orderUuid: orderId
        }
        await this.$store.dispatch(ORDER_GET_ORDER_INFO, { data })
        await this.setOrderInformation()
        this.isMakeOldOrderPayment = true

        if (this.isMakeOldOrderPayment) {
          this.depositAmount = this.order.depositAmount
        }
      } else if (action == 'buynow') {
        this.geBuyNowCartInformation()
      } else if (action == 'clearbalance') {
        this.getCheckoutCartInformation()
        await this.getClearBalanceCartDetails()
        this.isClearBalanceOrder = true
      } else {
        this.getCheckoutCartInformation()
      }
    },
    async setOrderInformation() {
      this.deliveryMethodId = this.order.deliveryMethodName.toLowerCase()
      await Promise.all(
        this.order.orderItems.map((prod) => {
          const data = {
            displaySku: prod.product_display_sku
          }
          return this.$store.dispatch(STOCK_OUTLET_STOCK_QUANTITY_BY_DISPLAY_SKU_FOR_CART, { data })
        })
      )
      this.promoCode = this.order.discountCode

      let orderItemsData = []
      this.order.orderItems.forEach((x) => {
        orderItemsData.push({
          productName: x.product_name,
          variation: x.product_variants
            .map((o) => {
              return o.value
            })
            .join('/'),
          productDisplaySku: x.product_display_sku,
          sku: x.product_sku,
          quantity: x.quantity,
          product_serials: [
            {
              serial_number: '',
              supplier_serial_number: ''
            }
          ],
          discountedPrice: x.purchase_price,
          deposit_amount: 0,
          discount_code: '',
          discount_percentage: '',
          discount_amount: 0,
          mark_up_price: 0,
          mark_up_percentage: '',
          mark_up_remark: '',
          product_type: PRODUCT_TYPE.PRODUCT,
          remark: '',
          group: 0
        })
      })
      this.checkoutCart = orderItemsData
      this.outletId = this.order.outletUuid
      this.updateOutletInfo()
      this.applyPromoCode()
    },
    getCheckoutCartInformation() {
      this.$store.dispatch(STOCK_RESET_OUTLET_STOCK_QUANTITY_BY_DISPLAY_SKU_FOR_CART_STORE)
      let checkoutCart = localStorage.getItem(SESSION.CHECKOUT_CART)
      if (checkoutCart) {
        this.checkoutCart = JSON.parse(checkoutCart)

        this.checkoutCart.forEach((x) => {
          let data = {
            displaySku: x.productDisplaySku
          }
          this.$store.dispatch(STOCK_OUTLET_STOCK_QUANTITY_BY_DISPLAY_SKU_FOR_CART, { data })
          this.maxFreeWrapping = this.maxFreeWrapping + x.quantity
        })
      } else {
        this.$router.push({ name: ROUTE_NAME.HOME })
      }
    },
    geBuyNowCartInformation() {
      this.$store.dispatch(STOCK_RESET_OUTLET_STOCK_QUANTITY_BY_DISPLAY_SKU_FOR_CART_STORE)
      let buyNowCart = localStorage.getItem(SESSION.BUYNOW_CART)
      if (buyNowCart !== undefined) {
        this.checkoutCart = JSON.parse(buyNowCart)

        this.checkoutCart.forEach((x) => {
          let data = {
            displaySku: x.productDisplaySku
          }
          this.$store.dispatch(STOCK_OUTLET_STOCK_QUANTITY_BY_DISPLAY_SKU_FOR_CART, { data })
          this.maxFreeWrapping = this.maxFreeWrapping + x.quantity
        })
      }
    },
    async getClearBalanceCartDetails() {
      this.clearBalanceOrderCart = await this.$store.state.order.orderHistory.data.filter((x) => x.orderReferenceNumber == this.$route.query.ref_num)[0]
      this.depositAmount = this.clearBalanceOrderCart.depositAmount
    },
    async getDeliveryMethod() {
      await this.$store.dispatch(LOOKUPS_ORDER_DELIVERY_METHODS)
    },
    async getCustomerInformation() {
      let data = {
        uuid: localStorage.getItem(SESSION.CUSTOMER_UUID)
      }
      await this.$store.dispatch(CUSTOMER_GET_INFORMATION, { data })
      this.setAddressInfo()
    },
    async getPaymentTypes() {
      this.$store.dispatch(LOOKUPS_PAYMENT_TYPES)
    },
    async getPaymentGateway() {
      let data = {
        currency: 'MYR'
      }
      this.$store.dispatch(PAYMENT_GATEWAY, { data })
    },
    async getOutlets() {
      await this.$store.dispatch(LOOKUPS_OUTLET_OUTLETS)
    },
    updateOutletInfo() {
      this.outletInfo = this.outlets.find((x) => x.uuid == this.outletId)
    },
    setAddressInfo() {
      if (!this.isMakeOldOrderPayment) {
        let billingAddressData = this.customerInfo.addresses.find((x) => x.is_default_billing)

        if (billingAddressData != undefined) {
          let billingAddressString = billingAddressData.address_1 + ', ' + billingAddressData.postcode + ' ' + billingAddressData.city + ', ' + billingAddressData.state

          this.billingAddress = {
            display_name: billingAddressData.display_name,
            phone: `${billingAddressData.phone_code}-${billingAddressData.phone_no}`,
            address: billingAddressString
          }
        }

        let shippingAddressData = this.customerInfo.addresses.find((x) => x.is_default_shipping)

        if (shippingAddressData != undefined) {
          let ShippingAddressString = shippingAddressData.address_1 + ', ' + shippingAddressData.postcode + ' ' + shippingAddressData.city + ', ' + shippingAddressData.state

          this.shippingAddress = {
            display_name: shippingAddressData.display_name,
            phone: `${shippingAddressData.phone_code}-${shippingAddressData.phone_no}`,
            address: ShippingAddressString
          }
        }
      } else {
        this.billingAddress = {
          display_name: this.order.emailCustomerName,
          phone: this.order.emailPhoneNumber,
          address: this.order.emailBillingAddress
        }

        this.shippingAddress = {
          display_name: this.order.emailPostageCustomerName,
          phone: this.order.emailPostagePhoneNumber,
          address: this.order.emailPostageAddress
        }
      }
    },
    async applyPromoCode() {
      await this.$store.dispatch(LOAN_CHECK_PROMO_CODE, { promoCode: this.promoCode })
      if (this.promoCodeData.code) {
        this.isEnterPromoCodeMode = false
        this.invalidPromoCodeError = ''
        this.isValidPromoCode = true
      } else {
        this.invalidPromoCodeError = this.$t('label.invalidPromoCode')
        this.isValidPromoCode = false
      }

      this.promoDiscount = this.promoCodeData.amount
      this.isValidPromoCode = !!this.promoCodeData.code
    },
    openAddAddressDialog() {
      this.$root.$master.deliveryAddressObj.isEdit = false
      this.$root.$master.deliveryAddressDialogShow = true
      this.$root.$master.deliveryAddressObj.isFirstAddress = this.customerInfo.addresses.length <= 0
    },
    openChangeBillingAddressDialog() {
      if (!this.isMakeOldOrderPayment) {
        this.selectedAddressId = this.billingAddress.uuid
        this.changeAddressDialogShow = true
        this.currentUpdateAddress = 'billing'
      }
    },
    openChangeShippingAddressDialog() {
      if (!this.isMakeOldOrderPayment) {
        this.selectedAddressId = this.shippingAddress.uuid
        this.changeAddressDialogShow = true
        this.currentUpdateAddress = 'shipping'
      }
    },
    updateSelectedAddress(value) {
      this.changeAddressDialogShow = false
      if (this.currentUpdateAddress == 'billing') {
        let billingAddressData = this.customerInfo.addresses.find((x) => x.uuid == value)

        let billingAddressString = billingAddressData.address_1 + ', ' + billingAddressData.postcode + ' ' + billingAddressData.city + ', ' + billingAddressData.state

        this.billingAddress = {
          display_name: billingAddressData.display_name,
          phone: `${billingAddressData.phone_code}-${billingAddressData.phone_no}`,
          address: billingAddressString
        }
      } else {
        let shippingAddressData = this.customerInfo.addresses.find((x) => x.uuid == value)

        let ShippingAddressString = shippingAddressData.address_1 + ', ' + shippingAddressData.postcode + ' ' + shippingAddressData.city + ', ' + shippingAddressData.state

        this.shippingAddress = {
          display_name: shippingAddressData.display_name,
          phone: `${shippingAddressData.phone_code}-${shippingAddressData.phone_no}`,
          address: ShippingAddressString
        }
      }
    },
    changePromoCode() {
      this.promoCode = ''
      this.promoDiscount = 0
      this.isValidPromoCode = false
      this.isEnterPromoCodeMode = true
    },
    async placeOrder() {
      let isCompleteInfo = true
      this.isDeliveryMethodError = false
      this.isPaymentMethodError = false
      this.isSelectOutletError = false
      this.isShippingAddressError = false
      this.isBillingAddressError = false
      this.engravingError = false

      if (this.billingAddress == null) {
        this.isBillingAddressError = true
        isCompleteInfo = false
      }

      if (this.deliveryMethodId == '' && !this.isDeposit) {
        this.isDeliveryMethodError = true
        isCompleteInfo = false
      }

      if (this.paymentGatewayId == '') {
        this.isPaymentMethodError = true
        isCompleteInfo = false
      }

      if (this.deliveryMethodId == DELIVERY_METHOD.SELF_PICKUP && this.outletId == '' && !this.isDeposit) {
        this.isSelectOutletError = true
        isCompleteInfo = false
      }

      if (this.deliveryMethodId != DELIVERY_METHOD.SELF_PICKUP) {
        this.outletId = ''
      }

      if (this.deliveryMethod != DELIVERY_METHOD.SELF_PICKUP && this.shippingAddress == null) {
        this.isShippingAddressError = true
        isCompleteInfo = false
      }

      if (this.engravingDataset.some((dataset) => dataset.quantity !== dataset.giftCardMessage.length)) {
        this.engravingError = true
        isCompleteInfo = false
      }

      if (isCompleteInfo) {
        let checkoutProduct = []
        let cartUuids = []

        this.checkoutCart.forEach((d) => {
          if (!cartUuids.includes(d.cartUuid) && d.cartUuid) cartUuids.push(d.cartUuid)
          checkoutProduct.push({
            linkage_order_item_uuid: this.isClearBalanceOrder ? this.clearBalanceOrderCart.orderItems.find((x) => x.product_display_sku == d.productDisplaySku).uuid : '',
            display_sku: d.productDisplaySku,
            sku: '',
            quantity: d.quantity,
            product_serials: [
              {
                serial_number: '',
                supplier_serial_number: ''
              }
            ],
            unit_price: d.discountedPrice,
            deposit_amount: 0,
            discount_code: '',
            discount_percentage: 0,
            discount_amount: 0,
            discount_remark: '',
            mark_up_price: 0,
            mark_up_percentage: 0,
            mark_up_remark: '',
            product_type: PRODUCT_TYPE.PRODUCT,
            remark: d.variation,
            cart_item_uuid: d.uuid,
            is_sync_cart: true
          })

          if (d.insurance && d.requiredInsurance) {
            checkoutProduct.push({
              display_sku: d.insurance.display_sku,
              sku: d.insurance.sku,
              quantity: d.insurance.quantity,
              product_serials: [
                {
                  serial_number: '',
                  supplier_serial_number: ''
                }
              ],
              unit_price: d.insurance.unit_price,
              deposit_amount: 0,
              discount_code: '',
              discount_percentage: 0,
              discount_amount: 0,
              discount_remark: '',
              mark_up_price: 0,
              mark_up_percentage: 0,
              mark_up_remark: '',
              product_type: PRODUCT_TYPE.PRODUCT,
              remark: `${d.insurance.remark}\nQuantity x${d.insurance.quantity}`
            })
          }
        })

        if (this.giftWrappingConfirmQuantity) {
          this.giftWrappingDataset.forEach((x) => {
            if (x.giftCardMessage.length > 0) {
              let extraInfo = []
              let obj = {}
              let key = x.fieldsName
              for (let j = 0; j < x.quantity; j++) {
                obj[key] = x.giftCardMessage.shift()
                extraInfo.push(obj)
              }
              checkoutProduct.push({
                display_sku: x.displaySku,
                sku: '',
                quantity: x.quantity,
                unit_price: 0.0,
                deposit_amount: 0.0,
                discount_code: '',
                product_type: PRODUCT_TYPE.SERVICE,
                discount_percentage: 0.0,
                discount_amount: 0.0,
                mark_up_price: 0.0,
                mark_up_percentage: 0.0,
                remark: this.giftInstruction,
                discount_remark: '',
                mark_up_remark: '',
                cart_item_uuid: null,
                is_sync_cart: false,
                extra_info: JSON.stringify(extraInfo)
              })
            }
          })
        }

        if (this.engravingConfirmQuantity) {
          this.engravingDataset.forEach((x) => {
            let extraInfo = []
            let obj = {}
            let key = x.fieldsName
            for (let j = 0; j < x.quantity; j++) {
              obj[key] = x.giftCardMessage.shift()
              extraInfo.push(obj)
            }
            checkoutProduct.push({
              display_sku: x.displaySku,
              sku: '',
              quantity: x.quantity,
              unit_price: 0.0,
              deposit_amount: 0.0,
              discount_code: '',
              product_type: PRODUCT_TYPE.SERVICE,
              discount_percentage: 0.0,
              discount_amount: 0.0,
              mark_up_price: 0.0,
              mark_up_percentage: 0.0,
              remark: this.engravingInstruction,
              discount_remark: '',
              mark_up_remark: '',
              cart_item_uuid: null,
              is_sync_cart: false,
              extra_info: JSON.stringify(extraInfo)
            })
          })
        }

        let data = {
          currency: 'MYR',
          dicountCode: this.promoCode,
          discountAmount: this.promoDiscount,
          contactEmail: this.customerInfo.email,
          contactPhoneNumber: `${this.customerInfo.phoneCode}-${this.customerInfo.phone}`,
          deliveryMethod: this.deliveryMethodId,
          outletId: this.outletId,
          billingName: this.billingAddress.display_name,
          billingAddress: this.billingAddress.address,
          billingPhoneNumber: this.billingAddress.phone,
          shippingName: this.deliveryMethodId != DELIVERY_METHOD.SELF_PICKUP ? this.shippingAddress.display_name : '',
          shippingAddress: this.deliveryMethodId != DELIVERY_METHOD.SELF_PICKUP ? this.shippingAddress.address : '',
          shippingPhoneNumber: this.deliveryMethodId != DELIVERY_METHOD.SELF_PICKUP ? this.shippingAddress.phone : '',
          product: checkoutProduct,
          linkageRefNumber: this.$route.query.ref_num,
          cartUuids: cartUuids,
          isDeposit: this.isDeposit,
          depositAmount: this.isClearBalanceOrder ? this.depositAmount : this.depositAmount * checkoutProduct[0].quantity
        }

        if (this.isDeposit) {
          await this.$store.dispatch(ORDER_PRE_ORDER, { data })
        } else {
          await this.$store.dispatch(ORDER_NORMAL_ORDER, { data })
        }
      }
    },
    confirmQuantityBeforePayment() {
      let cartItemsWithInsufficientQuantity = []
      this.checkoutCart.forEach((item) => {
        const itemStockQuantity = this.cartStock.find((x) => x.display_sku == item.productDisplaySku).quantity
        if (itemStockQuantity < item.quantity) {
          const message = item.variation ? `- ${item.productName} (${item.variation})` : `- ${item.productName}`
          cartItemsWithInsufficientQuantity.push(message)
        }
      })
      if (cartItemsWithInsufficientQuantity.length === 0) {
        this.makePayment()
      } else {
        this.$store.dispatch(SHARED_SHOW_DIALOG, {
          messageTitle: this.$t('label.headsUp'),
          messages: [this.$t('message.weDontHaveEnoughStockFor'), ...cartItemsWithInsufficientQuantity, '', this.$t('message.youCanStillCheckout'), '', this.$t('message.doYouWantToProceed')],
          buttons: [
            {
              type: 'default',
              title: this.$t('action.confirm'),
              action: () => {
                this.checkoutCart.forEach(this.checkAvailableQuantity)
                this.$store.dispatch(SHARED_CLOSE_DIALOG)
                this.$nextTick(() => {
                  this.makePayment()
                })
              }
            },
            {
              type: 'secondary',
              title: this.$t('action.cancel'),
              action: () => {
                this.$store.dispatch(SHARED_CLOSE_DIALOG)
              }
            }
          ]
        })
      }
    },
    makePayment() {
      let isCompleteInfo = true

      if (this.paymentGatewayId == '') {
        this.isPaymentMethodError = true
        isCompleteInfo = false
        this.$root.$master.showSnackbarMessage(localeHelper.getMessage(`message.pleaseSelectPaymentMethod`))
      }
      if (isCompleteInfo) {
        let d = this.$store.state.order.order
        let data = {
          paymentMethodId: this.paymentGatewayId,
          paymentAmount: d.purchasePrice - this.depositAmount,
          currency: d.currency,
          orderReferenceId: d.referenceNumber,
          customerName: d.emailCustomerName,
          customerEmail: d.emailRecipient,
          customerPhoneNumber: d.emailPhoneNumber
        }
        localStorage.removeItem(SESSION.CHECKOUT_CART)
        this.updateShoppingCart()
        this.$store.dispatch(PAYMENT_MAKE_PAYMENT, { data })
      }
    },
    updateShoppingCart() {
      let cartJsonString = localStorage.getItem(SESSION.CART)
      if (cartJsonString !== undefined && cartJsonString !== null) {
        let cart = JSON.parse(cartJsonString).product

        this.checkoutCart.forEach((x) => {
          cart = cart.filter((y) => y.display_sku != x.productDisplaySku)
        })

        sharedHelper.setCartItems(cart)
        this.$root.$master.updateCartInfo()
      }
    }
  }
}
</script>

<style lang="scss">
.delivery-option-button {
  width: 100% !important;
  border: 2px solid var(--v-light_gray-base);
  &.active {
    border-color: var(--v-unbox_primary-base);
    * {
      font-weight: bold !important;
    }
  }
}
.billing-address-box {
  background: #daccbf;
}
.pickup-location-box {
  div {
    margin: 12px 0 12px;
    border-bottom: 1px solid var(--v-text_light_gray-base);
  }
}

.checkout-payment-icon {
  width: 25px;
}

.delivery-option-button-box {
  max-width: 220px;
}

/* Gift Wrapping and Engraving*/
.gift-wrapping-engraving-section .v-expansion-panel-header {
  align-items: baseline;
}

.gift-wrapping-engraving-section .v-icon {
  color: #9d4823 !important;
}

.gift-wrapping-engraving-red {
  color: #9d4823;
}

.gift-wrapping-engraving-input {
  background-color: #f8fafc;
  border: #d5dde0 solid 1px;
  border-radius: 8px;
  width: 100%;
  resize: none;
}

.unbox-secondary {
  color: #706967;
}

.required-red {
  color: #ff0000;
}

.gift-wrapping-engraving-input::placeholder,
.gift-wrapping-engraving-input::-webkit-input-placeholder {
  color: #706967;
}

.gift-wrapping-engraving-input:focus {
  border-color: #d5dde0;
}

@media only screen and (max-width: 599px) {
  .delivery-option-button-box {
    max-width: 50%;
  }
}

@media only screen and (max-width: 400px) {
  .delivery-option-button-box {
    max-width: none;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.checkbox-ticker {
  width: 15px;
}

.cartDisable {
  opacity: 0.4;
}
</style>
